import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import DOMPurify from 'dompurify';
import { getSingleBlog, getAllBlogs } from '../services/operations/blogapi';

SwiperCore.use([Navigation, Pagination]);

const BlogDetail = () => {
    const { id } = useParams(); 
    const [blog, setBlog] = useState(null);
    const [allBlogs, setAllBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const blogData = await getSingleBlog(id);
                setBlog(blogData);

                const blogs = await getAllBlogs();
                setAllBlogs(blogs);
            } catch (error) {
                console.error("Failed to fetch blog details:", error);
            }
        };
        fetchBlogDetails();
    }, [id]);


     
    const truncateDescription = (description, wordLimit) => {
        if (!description) return '';
        const words = description.replace(/<[^>]+>/g, '').split(' '); // Remove HTML tags
        if (words.length <= wordLimit) return description;
        return `${words.slice(0, wordLimit).join(' ')}...`;
    };


    if (!blog) return <p className="text-center text-gray-500 dark:text-gray-400">Loading...</p>; 

    // Sanitize the blog description to prevent XSS attacks
    const sanitizedDescription = DOMPurify.sanitize(blog.description);

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold mb-8 text-center text-richblack-700 dark:text-white">{truncateDescription(blog.heading)}</h1>

            <div className="flex justify-center mb-8">
                <img src={blog.imageUrl || 'default-image-url'} alt={blog.heading} className="w-full max-w-xl lg:h-[400px] rounded-lg shadow-lg" />
            </div>

            <div className="flex flex-col items-center mb-16">
                <div
                    className="text-richblack-700 dark:text-richblack-50 leading-relaxed max-w-4xl"
                    dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
                />
            </div>

            <div className="mb-16">
                <h2 className="text-2xl font-semibold mb-4 text-richblack-700 dark:text-richblack-50">All Blogs</h2>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={1}  
                    navigation
                    loop={true} 
                    pagination={{ clickable: true }}
                    breakpoints={{
                        640: { // Small screens
                            slidesPerView: 1,
                        },
                        768: { // Medium screens
                            slidesPerView: 2,
                        },
                        1024: { // Large screens
                            slidesPerView: 4,
                        },
                    }}
                >
                    {allBlogs.map((blog) => (
                        <SwiperSlide key={blog._id}>
                            <Link to={`/blogs/${blog._id}`}>
                                <div className="bg-richblack-50 dark:bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl">
                                    <img src={blog.imageUrl || 'default-image-url'} alt={blog.heading} className="w-full h-48 object-cover rounded-lg" />
                                    <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-200">{truncateDescription(blog.heading)}</h3>
                                    <p className="text-gray-600 dark:text-gray-400 mt-2">{truncateDescription(blog.description, 10)}...</p>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </div>
    );
};

export default BlogDetail;
