import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { getAllBlogs, getLatestBlogs, getTrendingBlogs } from '../services/operations/blogapi';
import Footer from '../components/common/Footer';

// Add DOMPurify for sanitizing HTML content
import DOMPurify from 'dompurify';

SwiperCore.use([Navigation]);

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [trendingBlogs, setTrendingBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Fetch all blogs
                const allBlogs = await getAllBlogs();
                setBlogs(allBlogs);

                // Fetch latest blogs
                const latest = await getLatestBlogs();
                setLatestBlogs(latest);

                // Fetch trending blogs
                const trending = await getTrendingBlogs();
                setTrendingBlogs(trending);

            } catch (error) {
                console.error("Failed to fetch blogs:", error);
            }
        };
        fetchBlogs();
    }, []);

    const truncateDescription = (description, wordLimit) => {
        if (!description) return '';
        const words = description.replace(/<[^>]+>/g, '').split(' '); // Remove HTML tags
        if (words.length <= wordLimit) return description;
        return `${words.slice(0, wordLimit).join(' ')}...`;
    };

    const sanitizeContent = (content) => {
        return DOMPurify.sanitize(content); // Sanitize content to remove any HTML tags
    };

    const renderBlogSection = (title, blogList) => {
        return (
            <div>
                <h2 className="text-2xl font-semibold mb-4 text-richblack-600 dark:text-white">{sanitizeContent(title)}</h2>
                {blogList.length > 0 ? (
                    <Swiper
                        spaceBetween={20}
                        navigation
                        pagination={false}
                        breakpoints={{
                            640: { slidesPerView: 1 },
                            768: { slidesPerView: 2 },
                            1024: { slidesPerView: 3 },
                        }}
                    >
                        {blogList.map((blog) => (
                            <SwiperSlide key={blog._id}>
                                <Link to={`/blogs/${blog._id}`}>
                                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl">
                                        <img src={blog.imageUrl} alt={blog.heading} className="w-full h-48 object-cover rounded-lg" />
                                        <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-200">{truncateDescription(sanitizeContent(blog.heading))}</h3>
                                        <p className="text-gray-600 dark:text-gray-400 mt-2">{truncateDescription(sanitizeContent(blog.description), 10)}...</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <p className="text-richblack-400 dark:text-richblack-25">No blogs created in this category yet.</p>
                )}
            </div>
        );
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold mb-8 text-richblack-600 dark:text-white">Blogs</h1>

            {renderBlogSection('Latest Blogs', latestBlogs)}
            {renderBlogSection('Trending Blogs', trendingBlogs)}

            <div className="mb-16">
                <h2 className="text-2xl font-semibold mb-4 text-richblack-700 dark:text-richblack-5">All Blogs</h2>
                {blogs.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {blogs.map((blog) => (
                            <Link to={`/blogs/${blog._id}`} key={blog._id}>
                                <div className="bg-richblack-50 dark:bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl">
                                    <img src={blog.imageUrl} alt={blog.heading} className="w-full h-48 object-cover rounded-lg" />
                                    <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-200">{truncateDescription(sanitizeContent(blog.heading))}</h3>
                                    <p className="text-gray-600 dark:text-gray-400 mt-2">{truncateDescription(sanitizeContent(blog.description), 10)}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-600 dark:text-gray-400">No blogs created yet.</p>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default Blogs;
